import React, { useState, useEffect } from "react";
import { useForm } from "@formspree/react";
import { Button, Col, Form, Row } from "react-bootstrap";

function LandingContactFormNew({
  redirect,
  page,
  hideAboutYouField,
  hideAboutProjectField,
  formTitle,
  formButtonText,
  hideResetButton,
}) {
  const [state, handleSubmit] = useForm("xpwwvewd");
  const [gclid, setGclid] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const gclidParam = urlParams.get("gclid");
    if (gclidParam) {
      setGclid(gclidParam);
    }
  }, []);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: "generate_lead",
        form_name: "ContactForm",
        customer_email: formData.get("email"),
        customer_phone_number: formData.get("phone"),
      });
    }

    handleSubmit(event);
  };

  useEffect(() => {
    if (state.succeeded) {
      //   navigate(redirect || "/thank-you");
      window.location.href = redirect || `${window.location.origin}/thank-you`;
    }
  }, [state.succeeded]);

  const styles = {
    form: {
      maxWidth: "600px",
      margin: "0 auto",
    },
    labelCol: {
      textAlign: "left",
      paddingRight: "15px",
    },
    inputCol: {
      textAlign: "left",
    },
    input: {
      height: "60px",
      width: "100%",
      border: "1px solid #979797",
      borderRadius: "0px",
      background: "#FFF",
      padding: " 16px",
      boxShadow: "none",
      outline: "none",
    },
    textarea: {
      width: "100%",
      border: "1px solid #979797",
      borderRadius: "0px",
      background: "#FFF",
      padding: "8px 12px",
      boxShadow: "none",
      outline: "none",
    },
    button: {
      backgroundColor: "#f36e22",
      border: "none",
      color: "#fff",
      padding: "10px 20px",
      borderRadius: "4px",
      fontWeight: "bold",
      width: "100%",
    },
    resetButton: {
      backgroundColor: "transparent",
      border: "1px solid #f36e22",
      color: "#f36e22",
      padding: "10px 20px",
      borderRadius: "4px",
      fontWeight: "bold",
      width: "100%",
    },
    recaptcha: {
      margin: "20px 0",
    },
    row: {
      marginBottom: "15px",
    },
  };

  return (
    <Form style={styles.form} onSubmit={handleFormSubmit}>
      <p className="text-black-new text-center  fs-3 mb-3">
        {formTitle ?? "enquiry form"}
      </p>
      {/* First Name */}
      <Row className="align-items-center" style={styles.row}>
        <Col md={4} style={styles.labelCol}>
          <Form.Label className="mb-0">
            First Name<span style={{ color: "red" }}>*</span>
          </Form.Label>
        </Col>
        <Col md={8} style={styles.inputCol}>
          <Form.Control
            type="text"
            name="firstName"
            style={styles.input}
            required
          />
        </Col>
      </Row>

      {/* Last Name */}
      <Row className="align-items-center" style={styles.row}>
        <Col md={4} style={styles.labelCol}>
          <Form.Label className="mb-0">
            Last Name<span style={{ color: "red" }}>*</span>
          </Form.Label>
        </Col>
        <Col md={8} style={styles.inputCol}>
          <Form.Control
            type="text"
            name="lastName"
            style={styles.input}
            required
          />
        </Col>
      </Row>

      {/* Phone */}
      <Row className="align-items-center" style={styles.row}>
        <Col md={4} style={styles.labelCol}>
          <Form.Label className="mb-0">
            Phone<span style={{ color: "red" }}>*</span>
          </Form.Label>
        </Col>
        <Col md={8} style={styles.inputCol}>
          <Form.Control
            type="text"
            name="phone"
            style={styles.input}
            required
          />
        </Col>
      </Row>

      {/* Email */}
      <Row className="align-items-center" style={styles.row}>
        <Col md={4} style={styles.labelCol}>
          <Form.Label className="mb-0">
            Email<span style={{ color: "red" }}>*</span>
          </Form.Label>
        </Col>
        <Col md={8} style={styles.inputCol}>
          <Form.Control
            type="email"
            name="email"
            style={styles.input}
            required
          />
        </Col>
      </Row>

      {!hideAboutYouField && (
        <Row className="align-items-center" style={styles.row}>
          <Col md={4} style={styles.labelCol}>
            <Form.Label className="mb-0">
              About You<span style={{ color: "red" }}>*</span>
            </Form.Label>
          </Col>
          <Col md={8} style={styles.inputCol}>
            <Form.Select
              name="aboutYou"
              style={{
                ...styles.input,
                appearance: "none", // Remove the default arrow
                paddingRight: "40px", // Add space for the custom arrow
                background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 10'%3E%3Cpath d='M1 1l6 6 6-6' stroke='%23979797' stroke-width='2' fill='none'/%3E%3C/svg%3E") no-repeat right 12px center`,
                backgroundSize: "14px 10px", // Size of the custom arrow
              }}
              required
              defaultValue=""
            >
              <option value="" disabled>
                -None-
              </option>
              <option value="Architect">Architect</option>
              <option value="Interior Designer">Interior Designer</option>
              <option value="Developer">Developer</option>
              <option value="Contractor">Contractor</option>
              <option value="Residential Client">Homeowner</option>
              <option value="Commercial Client">Commercial Client</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Col>
        </Row>
      )}
      {!hideAboutProjectField && (
        <Row className="align-items-center" style={styles.row}>
          <Col md={4} style={styles.labelCol}>
            <Form.Label className="mb-0">
              Please tell us about your project
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
          </Col>
          <Col md={8} style={styles.inputCol}>
            <Form.Control
              as="textarea"
              name="message"
              rows={4}
              style={styles.textarea}
              required
            />
          </Col>
        </Row>
      )}
      {/* Hidden field for gclid */}
      <Form.Group controlId="gclid" className="d-none">
        <Form.Control type="hidden" name="gclid" value={gclid} />
      </Form.Group>
      <Form.Group controlId="page" className="d-none">
        <Form.Control type="hidden" name="page" value={page} />
      </Form.Group>

      {/* Buttons */}
      <Row className="justify-content-center">
        <Col lg={6}>
          <Button
            className="px-4 py-3 mt-3 mb-0 mx-auto w-100 daxPro-semibold"
            type="submit"
            variant="secondary"
            disabled={state.submitting}
          >
            {formButtonText ?? "SEND ENQUIRY"}
          </Button>
        </Col>
        {!hideResetButton && (
          <Col lg={6}>
            <Button
              variant="secondary"
              type="reset"
              className="px-4 py-3 mt-3 mb-0 mx-auto w-100 daxPro-semibold"
            >
              RESET
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default LandingContactFormNew;
